@import '../../common/scss-global/variables.scss';

.form-textfield {
    width: 100%;
}

.label-asterisk {
    display: none;
}

.input {
    font-size: 12px !important;
    color: $primary-black !important;
    font-family: $secondary-font-family !important;
}

.input-focus {
    color: $primary-black !important;
    font-size: 12px !important;
    font-weight: $bold !important;
    font-family: $secondary-font-family !important;
}
