@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary-font-family: 'Poppins', sans-serif;

$primary-h1-font-size: 24px;
$primary-h2-font-size: 20px;
$primary-h3-font-size: 16px;
$primary-h4-font-size: 12px;

$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-light: 400;

$body-regular-font-size: 12px;

@mixin text-body-regular-16($color: $primary-black) {
    font-size: 16px;
    font-weight: $font-weight-light;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-body-regular-14($color: $primary-black) {
    font-size: 14px;
    font-weight: $font-weight-light;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-body-regular-12($color: $primary-black) {
    font-size: 12px;
    font-weight: $font-weight-light;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-body-bold-16($color: $primary-black) {
    font-size: 16px;
    font-weight: $font-weight-bold;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-body-bold-14($color: $primary-black) {
    font-size: 14px;
    font-weight: $font-weight-bold;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-body-bold-12($color: $primary-black) {
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-h1-regular($color: $primary-black) {
    font-family: $primary-font-family;
    font-weight: $font-weight-bold;
    font-size: $primary-h1-font-size;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-h2-bold($color: $primary-black) {
    font-family: $primary-font-family;
    font-weight: $font-weight-bold;
    font-size: $primary-h2-font-size;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-h3-medium($color: $primary-black) {
    font-family: $primary-font-family;
    font-weight: $font-weight-medium;
    font-size: $primary-h3-font-size;
    line-height: normal;
    color: $color;
    font-display: fallback;
}

@mixin text-h4-medium-all-caps($color: $primary-black) {
    font-family: $primary-font-family;
    font-weight: $font-weight-bold;
    font-size: $primary-h4-font-size;
    line-height: normal;
    text-transform: uppercase;
    color: $color;
    font-display: fallback;
}

@mixin link-14 {
    font-family: $primary-font-family;
    font-weight: $font-weight-bold;
    font-size: 14px;
    line-height: normal;
    color: $primary-blue;
    font-display: fallback;
    text-decoration: none;
}

@mixin link-16 {
    font-family: $primary-font-family;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: normal;
    color: $primary-blue;
    font-display: fallback;
    text-decoration: none;
}
