@import '../scss-global/variables.scss';
@import '../scss-global/typography-rebrand.scss';

.loading-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.loading-text {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 700;
    font-family: $primary-font-family;
    text-transform: uppercase;
    text-align: center;
}

// MEDIA QUERIES

@media screen and (min-width: 540px) {
    .loading-text {
        font-size: 32px;
    }
}
