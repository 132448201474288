@import './common/scss-global/variables.scss';
@import './common/scss-global/fonts.scss';
@import './common/scss-global/normalize.scss';
@import './common/scss-global/typography-rebrand.scss';

body {
    color: $primary-black;
    margin: 0;
    padding: 0;
    font-family: $primary-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

button {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.outer-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content {
        flex: 1 0 auto;
    }

    &.with-nav {
        margin-top: 50px;
    }
}

.card {
    border-radius: 8px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.grid-card {
    border-radius: 8px !important;
    margin: 12px;
}

.grid {
    padding-bottom: 192px;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px !important;
}

.card-title {
    font-family: $primary-font-family;
    font-size: 18px !important;
    font-weight: $bold !important;
}

.card-subtitle {
    font-size: 12px !important;
    font-weight: 700 !important;
}

.title-margin-top {
    margin-top: 16px;
}

.paragraph-margin-top {
    margin-top: 8px;
}

.secondary-card-title {
    font-size: 14px !important;
    font-weight: 700 !important;
}

.card-subtext {
    font-size: 12px !important;
    white-space: pre-line;
}

// Media Queries

@media screen and (min-width: 680px) {
    .outer-container {
        &.with-nav {
            margin-top: 100px;
        }
    }
}

@media screen and (min-width: 780px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 16px;
    }

    .back-action {
        margin-top: 16px;
    }

    .card {
        margin-right: 16px;
    }

    .padding-desktop {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    .padding-bottom-desktop {
        padding-bottom: 32px !important;
    }
}

.hide {
    display: none !important;
}

@import './common/components/Header.scss';
@import './common/components/NavBar.scss';
@import './common/components/Loading.scss';
@import './common/components/PrimaryButton.scss';
@import './common/components/FormTextField.scss';
@import './common/components/FormSelectField.scss';
@import './common/components/FormTextArea.scss';
@import './common/components/FailureDialog.scss';
@import './account/components/Login.scss';
@import './parentalConsent/components/GrantParentalConsent.scss';
@import './coachManagement/components/AssistantCoachSettings.scss';
@import './coachManagement/components/AssistantCoaches.scss';
@import './coachManagement/components/AssistantCoachEmptyState.scss';
@import './coachManagement/components/CoachSettingsRowTemplate.scss';
@import './coachManagement/components/InviteCoachesModal.scss';
@import './common/components/DownloadButtonGroup.scss';
@import './coachManagement/components/AcceptAssistantCoachInvite.scss';
@import './planManagement/components/SubscriptionPlans.scss';
@import './planManagement/components/BraintreeDropIn.scss';
@import './payment/components/PaymentInfo.scss';
@import './billing/components/Billing.scss';
@import './integrations/components/IntegrationApiTokens.scss';