@import '../scss-global/variables.scss';

.failure-title {
    > h2 {
        font-size: 18px;
        font-weight: 700;
    }
    padding-bottom: 0px !important;
}

.failure-subtext {
    font-size: 14px !important;
    color: black !important;
}

.failure-button {
    width: 100px;
}

.failure-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 24px;
    margin-bottom: 24px;
}

.contact-support {
    color: $blue;
    text-decoration: none;
}
