// VERSION
$version: 1;

// THEME COLORS
$red: #e81017;
$green: #00bd76;
$blue: #0074e2;
$blue-hover: #349cff;
$blue-link: #008cf3;
$blue-secondary-hover: #e5eef7;
$blue-80: lighten($blue, 50%);
$grey: #3b3f44;
$dark-grey: #282b30;
$light-grey: #e5e3e3;
$grey-hover: #e5eef7;
$icon-grey: #4a4a4a;
$medium-grey: #afafaf;
$background-grey: #f7f7f7;
$default-header: #171b20;

//$primary-color-100: #1f242a;
$primary-color-90: #35393f;
$primary-color-80: #4c5055;
$primary-color-70: #626569;
$primary-color-60: #797c7f;
$primary-color-50: #8d8f91;
$primary-color-40: #a7a7a7;
$primary-color-30: #bdbdbd;
$primary-color-20: #d3d3d3;
$primary-color-10: #e9e9e9;
$primary-color-5: #f1f2f2;
$primary-color-2: #fbfbfb;
$primary-color-0: #ffffff;

$foreground: $primary-color-50;

$secondary-blue-1: #deebff;
$secondary-blue-2: #b3d4ff;
$secondary-blue-3: #4c9aff;

$secondary-red-1: #ffebe6;
$secondary-red-2: #ffbdad;
$secondary-red-3: #ff8f73;

$secondary-green-1: #e2ffee;
$secondary-green-2: #abf5d1;
$secondary-green-3: #79f2c0;

$secondary-yellow-1: #fffae6;
$secondary-yellow-2: #fff0b3;
$secondary-yellow-3: #ffe380;

$hover-shadow: #9d9d9d;
$box-shadow-default: 2px 2px 12px 0px rgba(203, 203, 203, 0.5);

// FONTS
$primary-font-family-bold: 'refrigerator_deluxebold';
$primary-font-family-heavy: 'refrigerator_deluxeheavy';
$primary-font-family-light: 'refrigerator_deluxelight';
$legacy-primary-font-family: 'Refrigerator Deluxe';
$secondary-font-family: 'Open Sans', sans-serif;
$default-font-color: #353636;
$heading-1-font-size: 30px;
$heading-2-font-size: 24px;
$heading-3-font-size: 18px;
$heading-4-font-size: 14px;
$heading-5-font-size: 12px;
$heading-6-font-size: 10px;

$icon-size: 24px;

// FONT WEIGHT
$semi-bold: 500;
$bold: 700;
$thin: 300;

// WIDTHS, HEIGHTS
$header-height: 64px;
$top-banner-margin: 32px;

//REBRAND COLOR PALLETTE (WIP)

$primary-highlighteryellow: #F1FD53;
$primary-blue: #0A0EFF;
$primary-black: #050310;
$primary-white: #FFFFFF;
$error-state-red: #E30013;

$secondary-neonred: #FF5261;
$secondary-neongreen: #00EE95;
$secondary-neonblue: #00F5DC;
$secondary-neonorange: #FFCA02;

$tint-yellow: #FFF200;
$tint-blue: #2164FF;
$tint-purple: #120B3B;
$tint-red: #E34A57;
$tint-green: #00BD75;
$tint-teal: #00B3B3;

$dark-mode-blue: #5C8DFF;
$dark-mode-highlighteryellow: #797D38;

$gray-01: #F7F7F7;
$gray-02: #F0EFEF;
$gray-03: #E5E3E3;
$gray-04: #C2C2C2;
$gray-05: #A9A9A9;
$gray-06: #868686;
$gray-07: #6D6D6D;
$gray-08: #525252;
$gray-09: #3D3D3F;
$gray-10: #2C2C2E;

$btn-tertiary-hover: #EEF4FF;
$btn-tertiary-focus: #E1E2FF;