@import '../../common/scss-global/variables.scss';

.form-container {
    margin: 32px;
}

.h4 {
    font-weight: 500;
    font-size: 34px;
    font-family: 'Poppins';
    margin-bottom: 16px;
}

.body1 {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins';
}

.paragraph {
    margin-bottom: 16px;
}

.h5 {
    font-weight: 500;
    font-size: 24px;
    font-family: 'Poppins';
    margin-top: 40px;
}

.h6 {
    font-weight: 500;
    font-size: 20px;
    font-family: 'Poppins';
    line-height: 32px;
    margin-bottom: 16px;
}

.overline {
    font-weight: 700;
    font-size: 12px;
    font-family: 'Poppins';
    text-transform: 'uppercase';
}

.todays-date {
    margin-top: 32px;
    margin-bottom: 16px;
}

.date-of-birth-form-input-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.parent-form-input-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.form-input-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    flex: 1;
}

.form-input-row-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    flex: 1;
}

.form-input-row-item-middle {
    margin-left: 16px;
    margin-right: 16px;
}

.form-input-row-item-left {
    margin-right: 16px;
}

@media screen and (max-width: 480px) {
    .form-container {
        margin: 16px;
    }

    .parent-form-input-row {
        flex-direction: column;
    }
}
