@import '../../common/scss-global/variables.scss';
@import '../../common/scss-global/typography-rebrand.scss';

.settings-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-family: $primary-font-family;
    font-weight: 400;
    color: $primary-black;
    line-height: 1.6;

    .settings-left {
        margin-bottom: 8px;
    }

    .action-column {
        text-align: center;
    }

    .description-column {
        text-align: center;
    }

    .action-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.italic {
    font-style: italic;
}

@media screen and (min-width: 660px) {
    .settings-row {
        flex-direction: row;
        justify-content: space-between;

        .settings-left {
            margin-bottom: 0;
        }

        .description-column {
            text-align: left;
        }
    }
}

@media screen and (min-width: 780px) {
    .settings-row {
        .settings-left {
            flex: 0.75;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .action-column {
            flex: 0.25;
        }

        .description-column {
            flex: 0.25;
            text-align: left;
        }

        .description-column:first-child {
            flex: 0.5;
        }
    }
}

@media screen and (min-width: 980px) {
    .settings-row {
        .action-row {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}
