@import '../../common/scss-global/variables.scss';

.invoice-date {
    color: $primary-blue;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.invoice-not-supported-type {
    width: 24px;
}

.invoice-paid-state {
    text-transform: capitalize;
}

.link-icon {
    color: $primary-blue;
    cursor: pointer;
}

.invoice-list {
    .settings-header,
    .settings-list {
        .settings-row {
            min-height: 42px;
            .description-column {
                display: flex;
                align-items: center;
            }
        }
    }

    .small-screen-show {
        display: none;
    }

    @media screen and (max-width: 720px) {
        .settings-row {
            flex-direction: row;
            .settings-left {
                flex: 1;
                margin-bottom: 0;
                .description-column {
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .settings-header,
        .settings-footer {
            .description-column {
                font-weight: 700;
            }
        }
    }
}

.invoice-details {
    section {
        padding-bottom: 8px;
    }
    .payment-status {
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 6px;
    }
    .invoice-detail-container {
        margin-top: 20px;
        width: 66%;
    }
    @media screen and (max-width: 720px) {
        .invoice-detail-container {
            width: 100%;
        }
    }
    .paid {
        color: $green;
        margin-left: 20px;
    }
}

.receipt-loading-dialog {
    font-size: $heading-3-font-size;
    font-weight: $bold;
    margin: 20% 0 0 40%;
}

.invoice-not-plan {
    width: 20.56px;
    height: 20.56px;
}
