@import '../../common/scss-global/variables.scss';
@import '../../common/scss-global/typography-rebrand.scss';

.integrations-container {
    margin-top: 117px;
    padding: 20px;
}

.zapier-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 6px;
    padding-top: 40px;
}

.integration-block
{
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 48px;

    button {
        margin: 0 auto;
        max-width: 300px;
    }

    a {
        margin-top: 16px;
        margin: 0 auto;
        max-width: 300px;
    }
}

.integration-title {
    font-family: $primary-font-family;
    font-size: 28px;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
}

.integration-about {
    font-size: 16px;
    line-height: 27px;
    padding-bottom: 32px;
    padding-top: 16px;
    text-align: center;
}

.integration-active-token {
    background: $primary-color-5;
    border-radius: 4px;
    display:flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 20px;
    width: 480px;

    .token {
        margin-right: 40px;
    }

    .token-title {
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.dialog-title {
    h2 {
        font-weight: bold;
    }
}