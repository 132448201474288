@font-face {
    font-family: 'Refrigerator Deluxe';
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.eot');
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.woff2') format('woff2'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.woff') format('woff'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.ttf') format('truetype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.svg#refrigerator_deluxeregular')
            format('svg'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Regular-webfont.svg#iconheroic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'refrigerator_deluxelight';
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.eot');
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.woff2') format('woff2'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.woff') format('woff'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.ttf') format('truetype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.svg#refrigerator_deluxelight')
            format('svg'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Light-webfont.svg#iconheroic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'refrigerator_deluxebold';
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.eot');
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.woff2') format('woff2'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.woff') format('woff'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.ttf') format('truetype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.svg#refrigerator_deluxebold')
            format('svg'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Bold-webfont.svg#iconheroic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'refrigerator_deluxeheavy';
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.eot');
    src: url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.eot?#iefix')
            format('embedded-opentype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.woff2') format('woff2'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.woff') format('woff'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.ttf') format('truetype'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.svg#refrigerator_deluxeheavy')
            format('svg'),
        url('https://static.trainheroic.com/fonts/RefrigeratorDeluxe-Heavy-webfont.svg#iconheroic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'iconheroic';
    src: url('https://static.trainheroic.com/fonts/iconheroic.eot');
    src: url('https://static.trainheroic.com/fonts/iconheroic.eot?#iefix') format('embedded-opentype'),
        url('https://static.trainheroic.com/fonts/iconheroic.woff') format('woff'),
        url('https://static.trainheroic.com/fonts/iconheroic.ttf') format('truetype'),
        url('https://static.trainheroic.com/fonts/iconheroic.svg#iconheroic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
