@import '../../common/scss-global/variables.scss';
@import '../../common/scss-global/typography-rebrand.scss';

.header-space {
    margin-top: 100px;
}

.form-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.help-text {
    font-family: $primary-font-family;
    font-size: 14px;
    font-weight: normal;
    margin: 24px 0;
}

.form-padding {
    padding: 0 16px;
}

.form-input {
    display: flex;
    flex-direction: column;
    height: 90px;
}

.signup-form .form-input {
    height: 70px;
}

.temp-link {
    font-size: 12px;
    padding-top: 10px;
    a {
        color: $blue;
        font-weight: 700;
        text-decoration: none;
    }
}

.terms-checkbox-container {
    align-items: center;
    display: flex;
}

.terms-container {
    color: $primary-black;
    font-family: $primary-font-family;
    font-size: 12px;
    text-align: left;
    > span {
        line-height: 1.8em;
    }
}

.terms-link {
    color: $primary-blue;
    font-weight: $font-weight-bold;
    outline: none;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.action-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 16px 8px !important;
}

.action-buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
}

.horizontal-divider {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 4px 0 !important;
    padding: 0 8px;
    width: 100%;
}

.divider-border {
    border: 0.5px solid $light-grey;
    flex: 2 auto;
}

.divider-text {
    flex: 1 auto;
    font-size: 10px;
    font-weight: $bold;
    text-align: center;
    text-transform: uppercase;
}

.title {
    color: $primary-black;
    font-family: $primary-font-family;
    font-size: 36px;
    font-weight: $font-weight-bold;
    line-height: 42px;
    font-style: italic;
    margin: 24px 0px 0px 0;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;

    &.underline-emphasis {
        position: relative;

        &::after {
            background: linear-gradient(to right, #ffef00, #ffb300);
            bottom: -6px;
            content: '';
            height: 6px;
            left: 0px;
            position: absolute;
            right: 0px;
            transform: skewX(-45deg);
        }
    }
}

.title-placeholder {
    height: 50px;
}

.title-highlight {
    margin-top: -44px;
}

.subtitle {
    color: $primary-color-0;
    font-family: $primary-font-family;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.75px;
    line-height: 1.8;
    margin: 0;
    text-align: center;
}

.button-margin {
    margin-top: 16px;
}

.promo-button {
    &:hover {
        background-color: transparent !important;
    }
}
.signup-form {
    @media screen and (min-width: 780px) {
        .horizontal-divider {
            padding: 0 24px;
        }

        .form-card {
            min-width: 400px;
        }

        .form-container {
            max-width: 66%;
        }
    }
}

.testimonial-section {
    background-image: url('../assets/Testimonial-800.jpg?v=#{$version}');
    background-position: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vw;
    margin-bottom: 100px;
    margin-top: 50px;
    max-height: 666px;
    min-height: 361px;
    position: relative;
}

.side-by-side-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.section-card {
    align-items: center;
    background-color: $primary-color-0;
    border-radius: 6px;
    box-shadow: $box-shadow-default;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.sign-up-form {
    margin-top: -36px;
}

.testimonial-container {
    flex: 1.6;
    margin: 0 32px 8px;
    .spacer {
        flex: 1;
        height: 20%;
    }
}

.title-container {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.testimonial-card {
    align-self: flex-start;
    display: flex;
    padding: 16px;
    width: 450px;

    .testimonial-content {
        color: $primary-black;
        font-family: $primary-font-family;
        font-size: 20px;
        font-weight: normal;
        line-height: 27px;
        margin: 8px 0;
        text-align: center;
    }

    .testimonial-author {
        font-family: $primary-font-family;
        font-size: 12px;
        font-weight: $bold;
        line-height: 1.5;
        margin-top: 6px;
        margin: 0;
        text-align: center;
    }

    > p {
        color: $medium-grey;
        font-size: 11px;
        margin-top: 4px;
        margin: 0;
        text-align: center;
    }
}

.logo-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10px;
    > div {
        align-items: center;
        display: flex;
        flex-basis: 15%;
        justify-content: center;
        padding-bottom: 16px;
        padding-top: 16px;
        > img {
            height: auto;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 780px) {
    body {
        margin-top: 0;
    }
    .signup-header {
        padding-top: 15px;
    }
    .side-by-side-layout {
        display: flex;
        flex-direction: column;
    }

    .testimonial-section {
        background-image: none;
        margin-bottom: 100px;

        .testimonial-container {
            background-image: url('../assets/Testimonial-1600.jpg?v=#{$version}');
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 24px;
            margin: 0;
            padding: 24px;
        }
        .testimonial-card {
            bottom: 60%;
            left: 32px;
            padding: 17px;
            right: 32px;
            transform: translateY(60%);
            width: auto;

            .quote-icon {
                height: 32px;
                width: 32px;
            }

            .testimonial-author {
                font-size: 12px;
            }

            .testimonial-content {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .logo-container {
        padding-top: 132px;
        > div {
            flex-basis: 30%;
        }
    }
}

@media screen and (min-width: 780px) {
    body {
        margin-top: 0;
    }
    .signup-header {
        padding-top: 15px;
    }
    .top-section {
        .title {
            font-size: 48px;
            margin-top: 18px;

            .underline-emphasis {
                &::after {
                    height: 5px;
                }
            }
        }
    }

    .testimonial-section {
        background-image: url('../assets/Testimonial-1600.jpg?v=#{$version}');
        background-position: bottom left;
        margin-bottom: 0;
        margin-top: 25px;

        .testimonial-card {
            bottom: 50%;
            left: 55%;
            padding: 32px;
            right: 32px;
            transform: translateY(60%);

            .quote-icon {
                height: 47px;
                width: 47px;
            }
        }
    }

    .action-button {
        .expand-button {
            flex: unset !important;
        }
    }

    .fire-icon {
        height: 47px;
        width: 47px;
    }
}

@media screen and (max-width: 1002px) and (min-width: 780px) {
    .testimonial-section {
        .testimonial-card {
            margin-left: -10px;
            margin-right: 30px;
            transform: translateY(10%);
            width: 220px;
        }
    }
}

@media screen and (min-width: 1440px) {
    .testimonial-section {
        .testimonial-card {
            margin-right: 100px;
            width: 642px;
        }
    }
}
