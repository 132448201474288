@import '../../common/scss-global/variables.scss';
@import '../../common/scss-global/typography-rebrand.scss';

.current-plan-info {
    display: flex;
    flex-direction: column;

    .athlete-count-container {
        display: flex;
    }

    .min-padding {
        padding: 0;
    }

    .athlete-count {
        font-family: $primary-font-family;
        font-size: 40px;
        font-weight: $font-weight-bold;
        margin-bottom: 24px;
        text-align: center;
        text-transform: uppercase;

        > .asterisk {
            font-family: $primary-font-family;
            font-size: 16px;
            vertical-align: super;
            margin-left: 2px;
        }
    }

    .invited-count {
        height: 20px;
        line-height: 1.4;
        font-size: 14px;
        font-family: $primary-font-family;
        text-align: center;
    }

    .align-center {
        text-align: center !important;
    }

    .subscription-plan-select {
        margin: 16px 0 24px 0;
        padding: 0 16px;

        .proration {
            line-height: 1.4;
            font-size: 14px;
            font-family: $primary-font-family;
        }
    }

    .payment-form {
        margin: 0;
    }

    .sales-contact {
        padding: 12px 0;
        font-size: 12px;
        font-family: $primary-font-family;
        text-align: center;
        font-weight: 400;
        line-height: 1.4;

        .sales-email {
            color: $blue;
            font-weight: 700;
            text-decoration: none;
        }
    }

    .account-overdue {
        color: $red;
        padding: 12px 0;
        font-size: 14px;
        font-weight: $bold;
        font-family: $primary-font-family;
        text-align: center;
        line-height: 1.4;
    }
}

.current-plan {
    .plan-updated {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        color: $green;

        > h3 {
            margin-left: 8px;
            font-size: 14px;
            font-family: $primary-font-family;
            font-weight: $bold;
        }
    }
}

.bold {
    font-weight: $bold;
}

.cancel-plan {
    .success-content,
    .cancel-content {
        line-height: 1.5;
        font-size: 14px;
        font-family: $primary-font-family;
        margin: 12px 0;
    }

    .success-content {
        margin: 0;

        a {
            color: $blue;
            font-weight: 700;
            text-decoration: none;
        }
    }

    .cancel-content:first-child {
        margin: 16px 0;
    }
}

// MEDIA QUERIES

@media screen and (min-width: 420px) {
    .current-plan-info {
        .current-plan {
            .action-container {
                flex-direction: row-reverse;
                padding-top: 8px !important;

                .action-buttons-container {
                    flex: 1;
                    align-items: unset;
                }

                .action-buttons-container:last-child {
                    margin-right: 6px;
                    margin-left: 0 !important;
                }
            }
        }
    }
}

@media screen and (min-width: 450px) {
    .current-plan-info {
        .athlete-count-container {
            flex-direction: row;

            .card {
                flex: 1;
            }

            .card:first-child {
                margin-right: 12px;
            }
        }
    }
}

@media screen and (min-width: 780px) {
    .current-plan-info {
        .subscription-plan-select {
            padding: 0 32px;
        }
    }

    .cancel-plan {
        .success-content {
            padding-left: 8px;
        }
    }
}

@media screen and (min-width: 890px) {
    .current-plan-info {
        flex-direction: row;

        .current-plan {
            flex: 1;
            max-width: 624px;
        }

        .athlete-count-container {
            .card {
                min-width: 200px;
            }
        }
    }

    .cancel-plan {
        .action-container {
            flex-direction: row-reverse;
            justify-content: center;
            padding-top: 8px !important;

            .action-buttons-container {
                align-items: unset;
            }

            .action-buttons-container:last-child {
                margin-right: 6px;
                margin-left: 0 !important;
            }
        }
    }
}
