@import '../../common/scss-global/variables.scss';

.form-select-field {
    width: 100%;
}

.form-select-label {
    color: $primary-black !important;
    font-size: 14px;
    line-height: 1.4 !important;
}

.select-input {
    font-size: 14px !important;
    color: $primary-black !important;
    font-family: $secondary-font-family !important;
}

.select-input-focus {
    color: $primary-black !important;
    font-size: 14px !important;
    font-family: $secondary-font-family !important;

    &:focus {
        background-color: transparent !important;
        color: $primary-black !important;
        font-size: 14px !important;
        font-family: $secondary-font-family !important;
    }
}
