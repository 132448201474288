@import '../scss-global/variables.scss';

.nav-bar {
    display: none;
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    flex-grow: 1;
    background-color: $primary-black;
    box-shadow: none;
    z-index: 3;
}

@media screen and (min-width: 675px) {
    .nav-bar {
        display: block;
    }
}
