@import '../../common/scss-global/variables.scss';
@import '../../common/scss-global/typography-rebrand.scss';

.management-header {
    display: flex;
    flex-direction: column;
    padding: 24px 0 8px 0;

    .title {
        @include text-h1-regular;
    }

    .invite-coaches {
        display: flex;
        flex-direction: column;
    }
}

.helper-text {
    padding: 32px 0;
    font-size: 14px;
    font-family: $primary-font-family;
    font-weight: normal;
    font-style: italic;
}

@media screen and (min-width: 660px) {
    .management-header {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 0;

        .title {
            margin-bottom: 0;
        }

        .invite-coaches {
            flex-direction: row;
        }
    }
}

@media screen and (max-width: 660px) {
    .management-header {
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 0;

        .title {
            @include text-h1-regular;
        }

        .invite-coaches {
            align-items: center;
            flex-direction: row;

            > button {
                min-width: 140px;
            }
        }
    }
}
