@import '../../common/scss-global/variables.scss';

[data-braintree-id='card'] {
    border: 0px;
    font-family: 'Poppins';
}

[data-braintree-id='wrapper'] {
    margin-bottom: 24px;
}

[data-braintree-id='toggle'] {
    color: white;
    background-color: $blue;
    font-weight: 700;

    &:hover {
        font-weight: 700 !important;
        background-color: $blue-hover;
    }
}

[data-braintree-id='methods-edit'] {
    color: $blue;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none !important;

    &:hover {
        color: $blue-hover;
    }
}

[data-braintree-id='upper-container']:before {
    content: none !important;
}

[data-braintree-id='card-sheet-header'] {
    display: none;
}

[data-braintree-id='sheet-error'] {
    display: none !important;
}


[data-braintree-id='cvv-field-group'],
[data-braintree-id='expiration-date-field-group'] {
    flex-basis: 50% !important;

    @media screen and (max-width: 720px) {
        flex-basis: 100% !important;
        
    }
}

[data-braintree-id='postal-code-field-group'] {
    flex-basis: 100% !important;
}

.braintree-dropin {
    font-family: 'Poppins';
}

.braintree-sheet--has-error .braintree-sheet {
    border: none;
}

.braintree-form__label {
    display: none !important;
}

.braintree-form__icon-container {
    display: none !important;
}

.braintree-method {
    outline: none;
    margin-bottom: 16px !important;
    border-width: 2px !important;
    border-radius: 5px !important;
}

.braintree-method__check {
    background-color: $green !important;
}

.braintree-large-button {
    &:hover {
        background-color: $blue-hover;
    }

    span {
        border: none;
    }
}

.braintree-method:only-child {
    outline: none;
    border-color: $blue !important;
    box-shadow: none !important;
}

.braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__field
    .braintree-form__hosted-field {
    border: none;
    border-bottom: 1px solid #495057;
    padding-left: 0px;
}

.braintree-form__field-group {
    margin-bottom: 22px !important;
}

.braintree-sheet__content--form
    .braintree-form__field-group
    .braintree-form__label {
    font-size: 12px;
    font-weight: 700;
}

.braintree-sheet__content--form {
    padding: 0px;
}

.braintree-methods--active:not(.braintree-methods--edit)
    .braintree-method--active {
    border-color: $green !important;

    &:focus {
        outline: none;
    }
}
