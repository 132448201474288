@import '../../common/scss-global/variables.scss';

.settings-container {
    border: 1px;
    border-radius: 8px;
    box-shadow: $box-shadow-default;
    padding: 0;
}

.settings-header {
    padding: 12px;
    border-bottom: 1px solid $dark-grey;

    .settings-row {
        .action-column {
            display: none;
        }
    }

    .description-column {
        display: none;
    }

    .description-column:first-child {
        display: block;
    }
}

.settings-footer {
    padding: 12px;
    border-top: 1px solid $dark-grey;

    .settings-row {
        .action-column {
            display: none;
        }
    }

    .description-column {
        display: none;
    }

    .description-column:first-child {
        display: block;
    }
}

.settings-list {
    margin: 0;
    padding: 0 8px;

    .settings-row {
        padding: 12px 8px;
        border-top: 1px solid $light-grey;
    }
}

.settings-list:first-child {
    border: none;
}

@media screen and (min-width: 660px) {
    .settings-header,
    .settings-footer {
        padding: 24px 32px;

        .settings-row {
            .action-column {
                display: block;
                flex: 0.35;
            }
        }
    }

    .settings-list {
        padding: 0 32px 8px;

        .settings-row {
            min-height: 72px;
            padding: 12px 0;
        }
    }
}

@media screen and (min-width: 780px) {
    .settings-header,
    .settings-footer {
        .description-column {
            font-weight: $bold;
            display: block;
        }

        .settings-row {
            .action-column {
                flex: 0.25;
            }
        }
    }
}
