@import '../../common/scss-global/variables.scss';

.accept-container {
    margin-left: 40px;
}

.order-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.payment-container {
    display: flex;
    flex-direction: column-reverse;
}

.card {
    margin-bottom: 12px;
    border-radius: 8px !important;
    display: flex;
    flex-direction: column;
}

.subscription-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
}

.subscription-option-price {
    position: absolute;
    right: 0;
    margin-right: 32px;
}

.order-summary {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1 130px;
    margin-top: 12px;
}

.actions-container {
    padding: 16px !important;
}

.team-logo {
    border-radius: 4px;
    height: 40px;
    width: 40px;
    margin-bottom: 8px;
}

.download {
    height: 222px;
    position: relative;
}

.download-card {
    position: relative;
}

.download-header {
    position: absolute;
    color: white;
    z-index: 1;
    max-width: 169px;
    top: 32px;
    left: 8px;
}

.download-color {
    color: white !important;
}

.black-subtext {
    color: black !important;
}

.phone {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 222px;
}

.divider {
    border-bottom: 1px solid $light-grey;
}

.spacing {
    margin-bottom: 16px !important;
}

.order-detail-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    text-align: left;
    padding-bottom: 16px;

    .subscription-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
            margin: 4px 0;
            line-height: 1;
        }
    }

    .subscription-detail-subtext {
        font-size: 11px;
        color: $medium-grey;
        font-style: italic;
    }
}

.order-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > h5 {
        margin: 24px 0;
        line-height: 1.25;
        font-size: 14px;
        font-weight: $bold;
    }
}

.card-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.padding-desktop {
    padding-bottom: 0 !important;
}

.padding-mobile {
    padding: 16px 12px 8px 12px !important;
}

.padding-bottom {
    padding-bottom: 24px;
}

.form-label {
    font-weight: 700;
    font-size: 12px;
    margin-top: 16px;
}

.form-input {
    font-size: 14px;
}

.warning-text {
    padding: 16px 12px;
    background: #fcc;
    border: 1px solid #900;
    border-radius: 10px;
    line-height: 1.4;
}

// Media Queries

@media screen and (min-width: 580px) {
    .actions-container {
        padding: 24px !important;
    }
}

@media screen and (min-width: 780px) {
    .order-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .payment-container {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .card {
        min-width: 348px;
        margin-right: 16px;
    }

    .payment-card {
        flex: 1;
        margin-right: 32px;
    }

    .padding-desktop {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    .padding-bottom-desktop {
        padding-bottom: 32px !important;
    }

    .padding-mobile {
        padding: 16px 16px 24px 16px !important;
    }
}
