@import '../../common/scss-global/variables.scss';

.empty-state {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .flex-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .empty-title {
        margin-top: 16px;
        font-size: 14px;
        font-family: $secondary-font-family;
        font-weight: $bold;
        text-align: center;
    }

    .info-card {
        margin: 24px 0 16px 0;
        padding: 16px;
        background-color: $background-grey;
        border-radius: 4px;

        .info-title {
            font-size: 14px;
            font-family: $secondary-font-family;
            font-weight: $bold;
            text-align: left;
        }

        .info-list {
            margin-bottom: 8px;
            padding-inline-start: 24px;
            padding-inline-end: 24px;

            > li {
                line-height: 24px;
                font-size: 12px;
                font-weight: normal;
            }
        }
    }
}
