@import '../scss-global/variables.scss';
@import '../scss-global/typography-rebrand.scss';

body {
    margin-top: 84px;
}

.header {
    align-items: center;
    background-color: $primary-black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    min-height: 64px;
    padding: 8px 16px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;

    > h1 {
        @include text-h1-regular($primary-white);
        font-style: italic;
        margin-left: 32px;
        text-transform: uppercase;
    }

    .coach-button {
        display: none;

        &:hover {
            opacity: 0.75;
        }
    }
}

.account-menu {
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
        background-color: transparent !important;
        cursor: pointer;
    }
}

.circle-avatar {
    border-radius: 20px;
    height: 40px;
    width: 40px;
}

.drop-down-text {
    font-family: $primary-font-family !important;
    font-size: 14px !important;
    font-weight: $font-weight-bold !important;
}

.account-home {
    color: $primary-color-0 !important;
}

.mobile-menu {
    color: $primary-black !important;
}

.mobile-menu-home {
    color: $primary-color-0 !important;
}

// MEDIA QUERIES

@media screen and (min-width: 680px) {
    .menu {
        > h1 {
            display: block;
        }

        .coach-button {
            align-items: center;
            color: $primary-color-0;
            display: flex;
            font-family: $primary-font-family;
            font-size: 14px;
            margin-right: 30px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
}
