@import '../scss-global/variables.scss';

.download-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.app-store-button {
    margin-bottom: 8px !important;
    background-image: url('https://static.trainheroic.com/images/appstore.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 42px;
    width: 122px;
}

.play-store-button {
    background-image: url('https://static.trainheroic.com/images/googleplay.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 42px;
    width: 138.5px;
}

// Media Queries

@media screen and (min-width: 580px) {
    .download-buttons-container {
        flex-direction: row;
        align-items: center;
    }

    .play-store-button {
        margin-left: 16px !important;
    }

    .app-store-button {
        margin-bottom: unset !important;
    }
}
