@import '../../common/scss-global/variables.scss';

.outstanding-balance {
    padding: 0px 16px;
    color: $red;
    font-size: 14px;
    font-weight: $bold;
    font-family: $secondary-font-family;
    text-align: center;
    line-height: 1.4;

    > p {
        padding: 0 24px;
    }
}
