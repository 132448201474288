@import '../../common/scss-global/variables.scss';

.invite-title {
    font-size: 14px;
    font-family: $secondary-font-family;
    font-weight: $bold;
    text-align: left;
}

.form-textarea {
    width: 100%;
    min-width: 320px;
}

.form-textarea:first-child {
    margin-bottom: 24px;
}
